import React from 'react';

declare const IMP: any;

const ServiceSubscribe: React.FC = () => {
  const onSubscribe = async (method: 'naverpay' | 'tosspay' | 'kakaopay' | 'html5_inicis') => {
    IMP.init('iamport');

    IMP.request_pay({
      pg: `${method}.test`,
      pay_method: `${method}`,
      merchant_uid: `mid_${new Date().getTime()}`,
      name: '카운트핏 프리미엄 서비스',
      amount: 19800,
      buyer_email: 'count-fit@naver.com',
      buyer_name: '카운트핏',
      buyer_tel: '010-1234-5678',
      buyer_addr: '경기도 수원시 영통구 광교로 156, 6층 2호 (이의동, 광교 비즈니스 센터)',
      buyer_postcode: '16506',
      app_scheme: 'example',
      digital: true,
    });
  };

  return (
    <section id="service-subscribe" className="bg-background-accent">
      <div className="container py-20">
        <h4 className="px-10  text-[2.8rem] font-extrabold">
          <span className="text-primary">카운트핏</span>을 지금 바로
          <br />
          시작해보세요.
        </h4>
        <h6 className="mt-[5rem] px-10  text-[1rem] font-bold">카운트핏 프리미엄 서비스</h6>
        <div className="mt-[1rem] flex lg:flex-row flex-col lg:gap-8">
          <div className="sm:flex-[2.5] pl-10 lg:pr-0 pr-10 lg:pb-20 pb-8 sm:grid sm:grid-cols-2 flex flex-row gap-8 sm:overflow-x-auto overflow-x-scroll hide-scrollbar">
            <div className="px-8 py-10 sm:min-w-fit min-w-[75dvw] flex flex-col justify-center bg-white rounded-lg shadow-md">
              <h6 className="font-bold">🏋️‍♀️ 디바이스</h6>
              <p className="mt-4 text-[0.825rem]">운동 자세를 체크하고 카운트할 수 있는 카운트핏의 디바이스를 제공해드립니다!</p>
            </div>
            <div className="px-8 py-10 sm:min-w-fit min-w-[75dvw] flex flex-col justify-center bg-white rounded-lg shadow-md">
              <h6 className="font-bold">🦾 운동 하기</h6>
              <p className="mt-4 text-[0.825rem]">
                카운트핏 디바이스와 연결하여 집에서도, 헬스장에서도 트레이너 없이 편리하고 확실하게 운동을 진행해보세요!
              </p>
            </div>
            <div className="px-8 py-10 sm:min-w-fit min-w-[75dvw] flex flex-col justify-center bg-white rounded-lg shadow-md">
              <h6 className="font-bold">✏️ 운동 일지</h6>
              <p className="mt-4 text-[0.825rem]">
                진행한 운동을 한번에 확인할 수 있어요, 개별 운동, 나의 루틴 운동, 카운트핏 루틴 등 다양한 운동 내역과 분석 내용을
                확인해보세요:)
              </p>
            </div>
            <div className="px-8 py-10 sm:min-w-fit min-w-[75dvw] flex flex-col justify-center bg-white rounded-lg shadow-md">
              <h6 className="font-bold">🖥️️ 커뮤니티</h6>
              <p className="mt-4 text-[0.825rem]">카운트핏 유저들과 나의 식단, 운동 내역 등 다양한 일상을 공유하고 소통해보세요!</p>
            </div>
          </div>
          <div className="lg:flex-1 lg:ml-0 ml-10 mr-10 py-10 lg:mb-20 lg:aspect-square bg-gradient-to-br from-[#83AEFF] to-primary flex flex-col justify-center items-center rounded-lg shadow-md">
            <p className="text-[1.1rem] text-white text-center">
              단 한번의 결제로 4가지 기능을
              <br />
              <span className="font-bold">무제한으로 이용</span>하세요:)
            </p>
            <span className="mt-1 text-[3rem] text-white font-bold">19,800원
              <span className="mt-1 text-[1rem] text-white"> / 1개월</span>
            </span>
            <span className="mt-1 text-[1rem] text-white">* 이용기간 종료 시 자동 연장되지 않는</span>
            <span className="text-[1rem] text-white">단건 결제 상품입니다.</span>
            
          </div>
        </div>
        <div className="flex lg:mt-0 mt-20 px-10 flex-row gap-4 items-center">
          <h6 className="text-[1.625rem] font-bold">결제하기</h6>
          <p className="text-[1rem]">원하는 방식으로 결제하세요:)</p>
        </div>
        <div className="mt-[1.5rem] px-10 flex flex-row flex-wrap gap-2 items-center">
          <button className="max-w-[10rem] aspect-[175/50] rounded-full" onClick={() => onSubscribe('naverpay')}>
            <img className="w-full h-full" src="/images/naver-pay.png" alt="NAVER PAY" />
          </button>
          <button className="max-w-[10rem] aspect-[175/50] rounded-full" onClick={() => onSubscribe('tosspay')}>
            <img className="w-full h-full" src="/images/toss-pay.png" alt="TOSS PAY" />
          </button>
          <button className="max-w-[10rem] aspect-[175/50] rounded-full" onClick={() => onSubscribe('kakaopay')}>
            <img className="w-full h-full" src="/images/kakao-pay.png" alt="KAKAO PAY" />
          </button>
          <button className="max-w-[10rem] aspect-[175/50] rounded-full" onClick={() => onSubscribe('html5_inicis')}>
            <img className="w-full h-full" src="/images/kg-inicis.png" alt="KG INICIS" />
          </button>
        </div>
        <div className="mt-[1.8rem] px-10 flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <h6 className="text-[1.1rem] font-bold">서비스 제공 기간</h6>
            <p>- 온라인 상품 구매 후 바로 사용가능</p>
          </div>
          <div className="flex flex-col gap-1">
            <h6 className="text-[1.1rem] font-bold">취소/환불 정책</h6>
            <p>- 결제 후 1일 내 count-fit@naver.com으로 요청 시 즉시 결제 취소 처리</p>
            <p>- 결제 후 7일 내 count-fit@naver.com으로 요청 시 즉시 환불 처리</p>
            <p>- 결제 후 7일 이후는 별도 약관에 따름</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSubscribe;
