import React from 'react';
import { CoopContactForm, MainVisual, ServiceGuide, ServiceIntroduction, ServiceSubscribe, ServiceSuggestion } from './components';

const Index: React.FC = () => {
  return (
    <main>
      <MainVisual />
      <ServiceIntroduction />
      <ServiceGuide />
      <ServiceSuggestion />
      <ServiceSubscribe />
      <CoopContactForm />
    </main>
  );
};

export default Index;
