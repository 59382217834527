export const INTRODUCES = [
  {
    title: '모션인식밴드를 회원에게 제공하고\n운동을 실시간으로 트랙킹하세요.',
    description: '<span>모션인식밴드를 착용하고</span>운동을 하면 운동별로 카운트가 자동으로 기록됩니다.',
  },
  {
    title: '상세하고 정확한 운동일지를\n회원과 공유하세요',
    description:
      'PT가 끝나면 세션동안 진행한 운동의 종류와 실시 횟수가 시간대별로 자동 생성됩니다. <span>운동일지를 회원과 트레이너가 함께 공유</span>하면서 무엇을 잘했는지 무엇이 부족한지 이야기 해 보세요.',
  },
  {
    title: '개인운동을 위한 루틴을 만들고\n회원에게 전송하세요.',
    description:
      '카운트핏은 회원의 <span>지난 PT 운동기록을 기반으로 빠르고 간단하게 운동루틴을 생성</span>할 수 있습니다. 카운트핏을 통해 운동숙제를 전송하세요 PT시간 이후에도 회원들을 위한 관리는 계속됩니다.',
  },
  {
    title: '회원이 PT가 없는날도 운동을 열심히\n하는지 원격으로 체크하세요.',
    description:
      '집에서든 헬스장에서든 카운트핏 모션인식밴드를 착용하고 운동을 하면 <span>실시간으로 트레이너에게도 회원 운동 내역이 자동</span>으로 전송됩니다.',
  },
];
