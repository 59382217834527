import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './global.css';

const { error } = console;

console.error = (...args: unknown[]) => {
  const filter = [
    'React does not recognize the',
    'Support for defaultProps will be removed from function components in a future major release.',
  ];

  if (args.some(arg => typeof arg === 'string' && filter.some(x => arg.includes(x)))) return;

  error(...args);
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
