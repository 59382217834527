import React from 'react';
import './page.scoped.css';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <main className="max-w-[900px] mx-auto py-12">
      <article id="4b565cea-94a7-4cc5-a377-180fda563f6e" className="page sans">
        <header>
          <h1 className="page-title">개인정보 처리방침 (2024. 08)</h1>
          <p className="page-description"></p>
        </header>
        <div className="page-body">
          <h3 id="d3318fc5-4655-4a08-aa36-3eae68bf993c" className="">
            개인정보 처리 목적
          </h3>
          <p id="1052e7bb-010b-4fb3-bb04-9e90c53d66f6" className="">
            주식회사 룸(이하 룸) 은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
            <br />
            서비스 제공 목적, 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인식별과 인증, 각종 이벤트나 광고성 정보 제공, 법령 등
            규정된 의무의 이행, 회원자격 유지 및 관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급. 배송 등<br />
          </p>
          <h3 id="a9557f08-8cfa-49b1-97f9-52de9066dfe3" className="">
            개인정보의 처리 및 보유 기간
          </h3>
          <p id="5be0faa2-8b82-477f-8fd8-11ebccb411f2" className="">
            ① 룸은(는) 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유 · 이용기간 또는 법령에 따른 개인정보 보유 · 이용기간
            내에서 개인정보를 처리 · 보유합니다.
            <br />② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
            <br />
            고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지
            <br />
            전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년
            <br />
          </p>
          <h3 id="025942c9-5f05-4b02-9864-5290475a684b" className="">
            개인정보의 수집 및 수집 조건
          </h3>
          <p id="a71ce42f-0e09-464d-8046-c5c9ab28e52f" className="">
            ① 룸은(는) 모바일 애플리케이션을 통한 회원가입, 고객센터 상담(전화 또는 이메일), 제휴사로부터의 제공, 이벤트 응모 등을 통해 개인
            정보를 수집합니다.
            <br />② SNS 가입의 경우 다음의 개인정보 항목을 수집하여 처리하고 있습니다.
            <br />
          </p>
          <p id="150d320b-3b5a-4e9a-8bf7-61ecb49c799c" className="">
            ③ SNS 가입의 경우 회원가입 유무 및 로그인 확인을 위하여 CI 정보를 수집하여 처리하고 있습니다.
          </p>
          <p id="e4323232-21fd-4b91-b49f-61e5667d6b03" className="">
            &lt;카카오 SNS&gt;
            <br />
            필수: 이름, 카카오계정(전화번호), CI(연계정보)
            <br />
          </p>
          <p id="20676d4f-7846-469d-bd9f-22ae2a32fa16" className="">
            &lt;네이버 SNS&gt;
            <br />
            필수: 이름, 이메일, 프로필사진 / 선택: 없음,CI(연계정보)
            <br />
            &lt;구글&gt;
            <br />
            필수: 이름, 이메일, 프로필사진 / 선택: 성별
            <br />
          </p>
          <p id="5fed8d88-1e46-4601-a126-6e8e82a04b4b" className="">
            &lt;다날&gt;
          </p>
          <p id="cbb0d8d4-c611-4cfd-b5b7-c456a0825c60" className="">
            필수: 전화번호, 이름, CI(연계정보) / 선택: 없음
            <br />③ 제휴 서비스 또는 단체 등으로부터 개인정보를 제공 받은 경우
            <br />
          </p>
          <p id="812b55cb-62e4-4840-9ec4-e8152e9446ac" className=""></p>
          <h3 id="1cea8c49-151d-4d1d-96bc-3c82e0ab11ca" className="">
            개인정보의 이용
          </h3>
          <p id="19d71a55-b313-4a25-bd35-e94475586b8e" className="">
            ① 다음 목적을 위해 수집한 정보를 사용할 수 있습니다.
          </p>
          <ul id="ce2f7437-48c1-4cd3-a524-471966d7c656" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>신체 데이터를 활용한 추천 서비스, 알림 서비스, 서비스 개선/테스트/모니터링</li>
          </ul>
          <ul id="c06ef2d1-777d-4c8c-842c-7a5a13042b35" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>신규 기능의 개발과 테스트</li>
          </ul>
          <ul id="2a870333-2b55-4454-8836-89529b23fb5d" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>기술적인 문제의 진단과 해결, 서비스 문제 해결</li>
          </ul>
          <ul id="147bfb30-8051-4ba4-9d14-7c7ddfc77990" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>서비스 문제 해결, 데이터 분석, 연구 활동 등을 포함한 내부 운영</li>
          </ul>
          <ul id="dc33cf08-7e90-4e89-b88f-2a9cffa15290" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>회원이 서비스를 이용하며 등록한 콘텐츠의 노출, 공유, 퍼블리시</li>
          </ul>
          <ul id="c4676e14-7c92-4cc0-8f80-9ec4c4881ae6" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>물품 또는 서비스의 공급. 배송 등</li>
          </ul>
          <p id="1531063b-bd28-4b59-9c8f-d231f58645e5" className="">
            ② 가입 의사 또는 탈퇴 의사 확인, 서비스 이용에 따른 이용자 확인 및 식별, 약관 또는 이용정책을 위반하는 이용자의 이용제한조치 등
            서비스 운영에 지장을 주는 행위 방지 및 부정이용방지, 분쟁조정을 위한 기록보존, 민원처리, 공지사항 전달 등을 위해 개인정보를
            이용합니다.
            <br />③ 서비스 이용기록 분석 및 통계와 그에 따른 맞춤형 서비스(광고 포함) 제공, 인구통계학적 특성에 따른 서비스 제공 및 맞춤형
            광고 게재, 결제 처리, 서비스 개발/제공 또는 업데이트, 이벤트 또는 광고성 정보 제공 등을 위해 개인정보를 이용합니다.
            <br />
          </p>
          <h3 id="9b6aecaa-beb9-4df5-bbdd-f5493804447a" className="">
            공개 가능 정보에 관한 사항
          </h3>
          <p id="83d90deb-ba85-4274-8b75-208037dddfa0" className="">
            룸이 제공하는 서비스에 가입하면, 기본적으로 프로필과 활동이 모든 사람에 공개됩니다. 프로필 정보와 공개 설정한 게시물은 다른
            카운트핏 이용자와 대중에게 보여지며, 정보 공개 설정에 따라 공유하는 추가 정보 및 콘텐츠도 보일 수 있습니다. 여기에서 대중은 룸이
            제공하는 서비스의 이용자 뿐 아니라 서비스 외부의 사람들도 포함하며, 서비스 외부로 공유 가능함을 의미합니다. 예를 들어, 동 서비스
            내 다른 이용자 및 비사용자에게 활동과 게시물이 접근 가능하거나 공개적으로 접근 가능한 페이지를 인터넷 검색 엔진 결과에서 볼 수
            있습니다.
          </p>
          <h3 id="82eaebaf-1967-4ddf-a82f-242d8eff66dd" className="">
            개인정보의 제3자 제공에 관한 사항
          </h3>
          <p id="a437b7bc-ca8c-420b-a011-9f6286395024" className="">
            룸은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
            제공합니다.
          </p>
          <h3 id="24b215dc-81b8-403f-9841-d92c4f5c1d9b" className="">
            개인정보 보유기간, 파기방법 및 이용기간
          </h3>
          <p id="6de05e1b-59d0-479f-9474-7f8561be03e9" className="">
            사용자 개인정보는 사용자로부터 동의를 받은 수집 및 이용목적이 달성된 때에는 회사 내부 방침 또는 관계 법령에서 정한 일정 기간
            동안 보관한 다음 파기합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일 형태로 저장된
            개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 룸이 내부 방침 또는 법령에 따라 보관하는 개인정보 및 해당
            법령은 아래 표와 같습니다.
          </p>
          <p id="52a4f20e-ba88-48fa-a7f9-998aba5b2397" className="">
            <br />
            <br />
            <strong>가. 회사 내부 방침에 의한 정보보유 사유</strong>
          </p>
          <p id="832e8c70-bb84-4e1b-91fe-3acbe5f83d7f" className="">
            보존 항목: 부정 이용 기록
            <br />
            보존 이유: 부정 이용 방지
            <br />
            보존 기간: 10년
            <br />
          </p>
          <p id="18a66c7a-6453-43c7-9527-10e279b5f250" className="">
            <strong>나. 관련 법령에 의한 정보보유 사유</strong>
          </p>
          <p id="eb13dde8-5fe8-4636-ac29-a738b64ce572" className="">
            보존 항목: 계약 또는 청약철회 등에 관한 기록
            <br />
            근거 법령: 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            보존 기간: 5년
            <br />
          </p>
          <p id="95c1749a-ab6f-49e6-9315-0269a8d7331b" className="">
            보존 항목: 대금결제 및 재화 등의 공급에 관한 기록
            <br />
            근거 법령: 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            보존 기간: 5년
            <br />
          </p>
          <p id="579f9686-e56d-464e-9444-79f7dae83ed7" className="">
            보존 항목: 소비자의 불만 또는 분쟁처리에 관한 기록
            <br />
            근거 법령: 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            보존 기간: 3년
            <br />
          </p>
          <p id="ec406923-d1b5-47a0-900e-1be9eec654d8" className="">
            보존 항목: 표시/광고에 관한 기록
            <br />
            근거 법령: 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            보존 기간: 6개월
            <br />
          </p>
          <p id="0d107884-ea78-471a-a4f1-e605e07346ae" className="">
            보존 항목: 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류
            <br />
            근거 법령: 국세기본법
            <br />
            보존 기간: 5년
            <br />
          </p>
          <p id="71073e23-2737-4a7f-9379-6d4fdbba1114" className="">
            보존 항목: 전자금융 거래에 관한 기록
            <br />
            근거 법령: 전자금융거래법
            <br />
            보존 기간: 5년
            <br />
          </p>
          <p id="99fa090f-6aed-4fad-8005-604aa17185e3" className="">
            보존 항목: 서비스 방문기록
            <br />
            근거 법령: 통신비밀보호법
            <br />
            보존 기간: 3개월
            <br />
          </p>
          <h3 id="a60e9cee-808a-442a-a08a-c2bebd9f512b" className="">
            정보주체의 권익침해에 대한 구제방법
          </h3>
          <p id="30426688-fcbc-4cc7-b26d-598d9aab4ef9" className="">
            사용자가 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로
            연락해 주시기 바랍니다. 룸은(는) 사용자 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
          </p>
          <h3 id="baa97f90-4bd3-498d-a614-960892b09753" className="">
            개인정보 보호책임자에 관한 사항
          </h3>
          <p id="42ffeb17-d1cf-47c6-9eaa-89cae97838c7" className="">
            룸은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </p>
          <p id="eb075566-2c4c-4341-9ca4-148de567bce4" className="">
            &lt;개인정보 보호책임자&gt;
            <br />
            이름: 오승현
            <br />
            직위: 대표
            <br />
            부서: 경영파트
            <br />
            연락처: 010-9186-3419
            <br />
            <a href="mailto:count-fit@naver.com">count-fit@naver.com</a>
          </p>
          <h3 id="b0459afb-63b8-440a-9e87-e5c8637dd735" className="">
            개인정보 처리방침의 변경
          </h3>
          <p id="46c742d4-4a9b-4b46-8eaf-591c0affd176" className="">
            룸은(는) 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이
            변경되는 경우 룸은(는) 변경 사항을 게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다.
          </p>
          <p id="d7b3ea1e-125d-4b70-b105-f66666d854bc" className="">
            공고일자: 2024년 11월 01일 / 시행일자: 2024년 11월 08일
          </p>
          <p id="209531f8-6067-4cd5-8ede-68ccee449478" className="">
            주식회사 룸의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못 하시거나 보다 자세한 도움이 필요하시면 아래의 기관으로
            문의하여 주시기 바랍니다.
          </p>
          <ul id="a22c0a56-dff0-4859-a43a-bf8c3b5cc2a1" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <br />
              홈페이지 : <br />
              <a href="http://privacy.kisa.or.kr/">privacy.kisa.or.kr</a>
              <br />
              전화 : (국번없이) 118
              <br />
              주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
              <br />
            </li>
          </ul>
          <ul id="996efb2f-9e52-4145-89bb-cfb68884d1c4" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
              <br />
              홈페이지 : <br />
              <a href="http://privacy.kisa.or.kr/">privacy.kisa.or.kr</a>
              <br />
              전화 : (국번없이) 118
              <br />
              주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
              <br />
            </li>
          </ul>
          <p id="8db46521-9a36-4fb3-9f11-735ee5952be7" className=""></p>
          <p id="dca8a078-30d4-435a-a0e6-ad1832d46f63" className=""></p>
          <p id="36348e8f-1d8b-4a31-9e66-83c8e8413db6" className="">
            2024.08 주식회사 룸
          </p>
        </div>
      </article>
      <span className="sans" style={{ fontSize: '14px', paddingTop: '2em' }}></span>
    </main>
  );
};

export default PrivacyPolicyPage;
