import React from 'react';
import { SERVICES } from './data';

const ServiceGuide: React.FC = () => {
  return (
    <section className="bg-background-accent">
      <div className="container lg:px-40 md:px-20 px-10 py-20">
        <h4 className="text-[3rem] text-primary font-bold text-center">서비스 안내</h4>
        <div className="mt-12 grid lg:grid-cols-3 grid-cols-2 gap-8">
          {SERVICES.map((service, i) => (
            <div key={i} className="flex flex-col items-center">
              <div className="w-[50%]">
                <img src={`/icons/service${i + 1}.svg`} alt={i.toString()} />
              </div>
              <p className="mt-4 text-[1rem] font-medium text-center whitespace-pre-wrap">{service.description}</p>
            </div>
          ))}
        </div>
        <div className="mt-20 p-4 bg-primary rounded-full">
          <p className="text-[1rem] text-white text-center font-medium">
            모션인식밴드는 PT 트레이너가 정한 기간동안 해당 PT 회원에게 무상대여되며 기간이 만료되면 카운트핏 본사에서 다시 회수합니다
          </p>
        </div>
      </div>
    </section>
  );
};

export default ServiceGuide;
