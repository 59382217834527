import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/components/ui';

const CoopContactForm: React.FC = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      representative: '',
      tel: '',
      address: '',
      email: '',
      taxpayerID: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    alert('파트너스 신청이 완료되었습니다');

    form.reset({});
    form.reset({});
  };

  return (
    <section>
      <div className="container px-10 py-40">
        <h4 className="text-[3rem] font-extrabold text-center">카운트핏 파트너스 (협력 파트너스 센터) 모집</h4>
        <p className="mt-12 text-center">
          카운트핏은 대형 헬스장은 물론 골목상권의 소규모 헬스장까지 모든 피트니스 비즈니스에 적합한 서비스를 제공하여
          <br className="lg:block hidden" />
          헬스장과 회원 그리고 카운트핏 모두 상생할 수 있는 생태계를 만들고자 합니다.
          <br className="lg:block hidden" />
          카운트핏 파트너스가 되셔서 저희의 서비스를 먼저 도입해 보세요 파트너스사 에게는
          <br className="lg:block hidden" />
          카운트핏 서비스를 3개월간 무료로 제공해 드립니다.(최대 계정 10개) <br className="lg:block hidden" />
        </p>
        <div className="mt-24 flex justify-center">
          <img className="w-[80%]" src="/images/countfit-x-partners.png" alt="Countfit x Partners" />
        </div>
        <div className="mt-32 flex flex-col items-center">
          <h5 className="text-[3rem] font-extrabold text-center">파트너스 신청을 위한 정보를 입력해 주세요</h5>
          <div className="mt-16  lg:w-[35%] md:w-[50%] sm:w-[70%] w-[90%]">
            <Form {...form}>
              <form className="flex flex-col gap-2" onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>헬스장 및 트레이너 이름</FormLabel>
                      <FormControl>
                        <Input placeholder="카운트핏" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="representative"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>대표자 이름</FormLabel>
                      <FormControl>
                        <Input placeholder="홍길동" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="tel"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>전화번호</FormLabel>
                      <FormControl>
                        <Input placeholder="01012345678" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="address"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>주소</FormLabel>
                      <FormControl>
                        <Input placeholder="경기도 수원시 영통구 광교로 156, 6층 2호" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>이메일</FormLabel>
                      <FormControl>
                        <Input placeholder="count-fit@naver.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="taxpayerID"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>사업자번호</FormLabel>
                      <FormControl>
                        <Input placeholder="000-00-00000" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <h6 className="mt-12 text-[1rem] font-bold text-center">파트너스에 관련된 문의는 아래 연락처로 연락 바랍니다.</h6>
                <address className="mt-4 not-italic text-[0.8rem] text-foreground-accent text-center">
                  <p>전화: 010-9186-3419</p>
                  <p>이메일: count-fit@naver.com</p>
                  <p>주소: 경기도 수원시 영통구 광교로 156, 6층 2호 (이의동, 광교 비즈니스 센터)</p>
                </address>
                <Button className="mt-8 w-full" type="submit">
                  제출하기
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

const formSchema = z.object({
  name: z.string({ message: '헬스장 및 트레이너 이름을 입력해주세요.' }).min(1, '올바른 헬스장 및 트레이너 이름을 입력해주세요.'),
  representative: z.string({ message: '대표자 이름을 입력해주세요.' }).min(1, '올바른 대표자 이름을 입력해주세요.'),
  tel: z.string({ message: '전화번호를 입력해주세요.' }).regex(/^[0-9]{6,12}/, '올바른 전화번호를 입력해주세요.'),
  address: z.string({ message: '주소를 입력해주세요.' }).min(1, '올바른 주소를 입력해주세요.'),
  email: z.string({ message: '이메일을 입력해주세요.' }).regex(/^[^@]+@[^@]+\.[^@]+$/, '올바른 이메일을 입력해주세요.'),
  taxpayerID: z
    .string({ message: '사업자번호를 입력해주세요.' })
    .regex(/^[0-9]{3}-[0-9]{2}-[0-9]{5}$/, '올바른 사업자번호를 입력해주세요.'),
});

export default CoopContactForm;
