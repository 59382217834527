import { cn } from '@/utils';
import React from 'react';
import { INTRODUCES } from './data';

const ServiceIntroduction: React.FC = () => {
  return (
    <section>
      <div className="container px-10 lg:py-0 lg:my-[-40px] py-20">
        <div className="relative">
          <div className="lg:flex lg:flex-row items-center">
            <div className="flex-[2]">
              <div className="w-[15rem] aspect-[240/53]">
                <img src="/images/logo-with-text-primary.png" alt="countfit" />
              </div>
              <h4 className="mt-8 text-[2.8rem] font-extrabold">카운트핏의 서비스를 소개합니다</h4>
              <p className="mt-8 text-[1.4rem] text-foreground-accent">
                이제는 카운트핏에서 쉽게 회원들을 관리하세요,
                <br />
                모션인식밴드를 이용한 운동 숙제, 나의 PT일정 관리, 회원 관리, 운동일지 등<br />
                다양한 서비스를 이용해보세요.
              </p>
            </div>
            <div className="flex-1 aspect-[855/1795] relative lg:scale-100 scale-75">
              <img className="lg:absolute top-[-5rem] left-0" src="/images/app-sample.png" alt="SAMPLE" />
            </div>
          </div>
          <div className="w-full h-full absolute lg:bottom-32 bottom-0 left-0 flex flex-col justify-end items-center">
            <span className="text-[1.5rem] font-extrabold">view-more</span>
            <img className="mt-4 animate-bounce" src="/images/arrow-down.png" alt="GO DOWN" />
          </div>
        </div>
      </div>
      <div id="service-introduce">
        {INTRODUCES.map((introduce, i) => (
          <div key={i} className={i % 2 === 0 ? 'bg-background-accent' : 'bg-white'}>
            <div className={cn('container px-10 py-20 flex flex-col items-center', i % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse')}>
              <div className={cn('flex-1 flex md:scale-100 scale-75', i % 2 === 0 ? 'justify-start' : 'justify-end')}>
                <img src={`/images/introduce${i + 1}.png`} alt={i.toString()} />
              </div>
              <div className="flex-1 md:mt-0 mt-8">
                <p className="text-primary text-[2.25rem] font-black">{(i + 1).toString().padStart(2, '0')}</p>
                <h4 className="text-[2.25rem] font-bold leading-[1.2] whitespace-pre-wrap">{introduce.title}</h4>
                <p
                  className="mt-10 [&>span]:text-primary [&>span]:font-bold text-[1.2rem] leading-[1.4]"
                  dangerouslySetInnerHTML={{ __html: introduce.description }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceIntroduction;
