import { cn } from '@/utils';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Header: React.FC = () => {
  const [scrollYOverMainVisual, setScrollYOverMainVisual] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => setScrollYOverMainVisual(window.scrollY > window.innerHeight);

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <header className={cn('py-5 w-full fixed top-0 left-0 z-40', scrollYOverMainVisual && 'bg-white/25 backdrop-blur-sm shadow-sm')}>
      <div className="container px-10 flex flex-row justify-between items-center">
        <div className="w-32">
          <img
            className="w-full h-full"
            src={scrollYOverMainVisual ? '/images/logo-with-text-primary.png' : '/images/logo-with-text.png'}
            alt="countfit"
          />
        </div>
        <div className="md:hidden flex flex-col gap-2 cursor-pointer" onClick={() => setOpen(true)}>
          <div className={cn('w-[24px] h-[2px]', scrollYOverMainVisual ? 'bg-black' : 'bg-white')} />
          <div className={cn('w-[24px] h-[2px]', scrollYOverMainVisual ? 'bg-black' : 'bg-white')} />
          <div className={cn('w-[24px] h-[2px]', scrollYOverMainVisual ? 'bg-black' : 'bg-white')} />
        </div>
        <nav className="md:flex hidden flex-row gap-6 items-center">
          <a className={cn('text-[1.125rem] font-bold', scrollYOverMainVisual ? 'text-black' : 'text-white')} href="#service-introduce">
            서비스 소개
          </a>
          <a className={cn('text-[1.125rem] font-bold', scrollYOverMainVisual ? 'text-black' : 'text-white')} href="#service-suggestion">
            센터 도입
          </a>
          <a className={cn('text-[1.125rem] font-bold', scrollYOverMainVisual ? 'text-black' : 'text-white')} href="#service-subscribe">
            프리미엄 서비스
          </a>
          <a className={cn('text-[1.125rem] font-bold', scrollYOverMainVisual ? 'text-black' : 'text-white')} href="#service-introduce">
            파트너스 신청
          </a>
        </nav>
      </div>
      {createPortal(
        <div
          className={cn('w-full h-full fixed top-0 left-0 bg-white md:hidden justify-center items-center z-50', open ? 'flex' : 'hidden')}
        >
          <nav className="flex flex-col gap-12">
            <a className="text-[3rem] font-bold" href="#service-introduce" onClick={() => setOpen(false)}>
              서비스 소개
            </a>
            <a className="text-[3rem] font-bold" href="#service-suggestion" onClick={() => setOpen(false)}>
              센터 도입
            </a>
            <a className="text-[3rem] font-bold" href="#service-subscribe" onClick={() => setOpen(false)}>
              프리미엄 서비스
            </a>
            <a className="text-[3rem] font-bold" href="#service-introduce" onClick={() => setOpen(false)}>
              파트너스 신청
            </a>
          </nav>
          <div className="fixed top-10 right-10 cursor-pointer" onClick={() => setOpen(false)}>
            <div className="w-[24px] h-[2px] bg-black absolute top-0 right-0 rotate-45" />
            <div className="w-[24px] h-[2px] bg-black absolute top-0 right-0 rotate-[135deg]" />
          </div>
        </div>,
        document.querySelector('#modal') as Element,
      )}
    </header>
  );
};

export default Header;
